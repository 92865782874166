import classNames from 'classnames'
import classes from './DualOption.module.scss'
import { useState } from 'react'

const DualOption = ({ onChangeOption, options, defaultOption }) => {
  const [selectedOption, setSelectedOption] = useState(
    options.find(({ value }) => value === defaultOption)?.value ||
      options[0].value,
  )

  const handleOptionClick = (option) => {
    setSelectedOption(option)
  }

  return (
    <div className={classes['dual-option']}>
      {options.map(({ value, label, additionalInfo }) => (
        <div
          key={`${label}-dual-option`}
          className={classNames(
            classes['dual-option__options'],
            selectedOption === value &&
              classes['dual-option__options--selected'],
          )}
          onClick={() => {
            handleOptionClick(value)
            onChangeOption(value)
          }}
        >
          {label}
          {additionalInfo && (
            <span className={classes['dual-option__options_additional']}>
              {additionalInfo}
            </span>
          )}
        </div>
      ))}
    </div>
  )
}

export default DualOption
