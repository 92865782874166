import Image from "next/image";
import classes from "./ProductHeroImages.module.scss";

const ProductHeroImages = ({ assets }) => {
  return (
    <div className={classes["product-hero-images"]}>
      {assets.map((asset) => (
        <span
          key={asset.assetId}
          className={classes["product-hero-images__image"]}
        >
          <Image
            width={50}
            height={50}
            alt="product informative image"
            src={asset.url}
          />
        </span>
      ))}
    </div>
  );
};

export default ProductHeroImages;
