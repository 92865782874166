import * as Icons from '@mui/icons-material'

const DynamicIcon = ({ name, ...props }) => {
  const IconComponent = Icons[name]

  if (!IconComponent) {
    console.error(`Icon "${iconName}" not found`)
    return null
  }

  return <IconComponent {...props} />
}

export default DynamicIcon
