import ReviewStarsWithCounter from '@/components/Review/ReviewStarsWithCounter/ReviewStarsWithCounter'
import classes from './ProductHeroHeader.module.scss'
import { useRef } from 'react'
import classNames from 'classnames'

const ProductHeroHeader = ({
  isMobile,
  title,
  productLocation,
  productReviewInfo,
  productTag,
  scrollVariant,
  description,
}) => {
  const flagRef = useRef()

  if (isMobile) {
    return (
      <div
        className={classNames(
          classes['product-hero-header'],
          isMobile && classes['product-hero-header--mobile'],
        )}
      >
        <div className={classes['product-hero__header__title-container']}>
          <h1>{title}</h1>
          {productTag && (
            <span
              className={classes['product-hero__tag']}
              ref={flagRef}
              style={
                flagRef?.current?.offsetWidth
                  ? { right: -flagRef.current.offsetWidth - 10 }
                  : {}
              }
            >
              {productTag}
            </span>
          )}
        </div>
        {productLocation && <h4>{productLocation}</h4>}
        {productReviewInfo && (
          <ReviewStarsWithCounter numberOfReviews={12} rating={5} />
        )}
      </div>
    )
  }

  return (
    <>
      <div className={classes['product-hero-header']}>
        <h1>{title}</h1>
        {productLocation && <h4>{productLocation}</h4>}
        {productReviewInfo && (
          <ReviewStarsWithCounter
            numberOfReviews={productReviewInfo.totalReviews}
            rating={productReviewInfo.average}
          />
        )}
        {productTag && (
          <span
            className={classes['product-hero__tag']}
            ref={flagRef}
            style={
              flagRef?.current?.offsetWidth
                ? { right: -flagRef.current.offsetWidth - 10 }
                : {}
            }
          >
            {productTag}
          </span>
        )}
      </div>
      <p
        className={classNames(
          classes['product-hero-header__description'],
          scrollVariant && classes['product-hero-header__description--hidden'],
        )}
      >
        {description}
      </p>
    </>
  )
}

export default ProductHeroHeader
