import classNames from 'classnames'
import classes from './Typography.module.scss'
import colors from '@/styles/theme/colors'

const Typography = ({
  as = 'span',
  color,
  bold,
  fontFamily = 'bebas',
  variant,
  children,
  textAlignment,
}) => {
  const Element = as

  return (
    <Element
      className={classNames(
        classes['typography'],
        classes[`typography--${fontFamily}`],
        bold && classes['typography--bold'],
        classes[`typography--${variant}`],
      )}
      style={{
        color: colors[color],
        textAlign: textAlignment,
      }}
    >
      {children}
    </Element>
  )
}

export default Typography
