import { PortableText } from '@portabletext/react'
import Image from 'next/image'
import Link from 'next/link'

import classes from './SplitHero.module.scss'
import IconBullet from '@/svgs/list-item.svg'
import ResponsiveComponent from '@/components/ResponsiveComponent'
import parse from 'html-react-parser'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'

const SplitHero = ({ fields, imagePriority }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 765px)' })
  if (!fields) {
    return <></>
  }

  const {
    imageContainer,
    imageWidth,
    style,
    textColor,
    valueProps,
    disclaimer,
    desktopBackgroundImage,
    mobileBackgroundImage,
    alt,
    decorativeImage,
    secondaryTextColor,
  } = fields

  const id = fields?.title?.replace(' ', '-')?.toLowerCase()

  const renderDecorativeImage = () => {
    const sizes = isMobile
      ? { height: 198, width: 200 }
      : { height: 300, width: 400 }

    return (
      <Image
        className={classNames(
          classes['hero__decorative-img'],
          `${classes[`hero__decorative-img--${decorativeImage.imagePlacement}`]}`,
        )}
        src={decorativeImage?.image?.asset?.url}
        {...sizes}
        alt="Decorative image"
      />
    )
  }

  return (
    <div
      id={id || ''}
      className={`${classes['hero']} ${classes[style]} ${classes[imageContainer]} ${classes[textColor]}`}
    >
      {!isMobile && decorativeImage?.image && renderDecorativeImage()}
      <div className={`${classes['hero__row']}`}>
        <div className={classes['hero__text']}>
          {isMobile && decorativeImage?.image && renderDecorativeImage()}
          <div className={classes['hero__text--inner']}>
            {fields.header && (
              <h1 className={classes[textColor]}>{fields.header}</h1>
            )}
            {fields.subheader && (
              <h2
                className={classNames(
                  classes['subheader'],
                  secondaryTextColor && classes[`hero__${secondaryTextColor}`],
                )}
              >
                {fields.subheader}
              </h2>
            )}

            {valueProps && (
              <ul className={classes['value-props']}>
                {valueProps.map((prop) => (
                  <li className="body" key={prop}>
                    <span>
                      <IconBullet />
                    </span>
                    {prop}
                  </li>
                ))}
              </ul>
            )}

            <div className={classes['btn-wrap']}>
              {fields.primaryCtaUrl && (
                <Link href={`${fields.primaryCtaUrl}`} legacyBehavior>
                  <a
                    className={`${classes['primary-btn']} btn salmon no-underline text-align--center`}
                  >
                    {fields.primaryCtaText}
                  </a>
                </Link>
              )}

              {fields.secondaryCtaUrl && (
                <Link href={`${fields.secondaryCtaUrl}`} legacyBehavior>
                  <a className={`btn--only-mobile ${classes['secondary-btn']}`}>
                    {fields.secondaryCtaText}
                  </a>
                </Link>
              )}

              {!fields.secondaryCtaUrl && fields.secondaryCtaText && (
                <h2 className={classes['secondary-text']}>
                  {parse(fields.secondaryCtaText)}
                </h2>
              )}
            </div>

            {disclaimer && (
              <div className={`${classes['disclaimer']} body`}>
                <PortableText value={disclaimer} />
              </div>
            )}
          </div>
        </div>

        <ResponsiveComponent
          desktopBreakpoint={'xs'}
          className={`${classes['hero__wrap']} ${classes[imageWidth]}`}
          mobileClassName={classes['hero__wrap--mbl']}
          desktopClassName={classes['hero__wrap--dsktp']}
          mobile={
            <Image
              sizes="50vw"
              className={classes.dsktp__img}
              src={mobileBackgroundImage?.asset?.url}
              layout="fill"
              priority={imagePriority}
              alt={alt || ''}
            />
          }
          desktop={
            <Image
              className={classes.mbl__img}
              src={desktopBackgroundImage.asset.url}
              layout="fill"
              priority={imagePriority}
              alt={alt || ''}
            />
          }
        />
      </div>
    </div>
  )
}

export default SplitHero
