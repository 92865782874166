import React from 'react';
import classes from './RoundedCheckbox.module.scss';

const RoundedCheckbox = ({ checked, onChange }) => {
  return (
    <label className={classes['rounded-checkbox']}>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className={classes['rounded-checkbox__checkmark']} />
    </label>
  );
};

export default RoundedCheckbox;
