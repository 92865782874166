import { useState, useEffect } from 'react'
import Image from 'next/image'
import ResponsiveImage from '@/components/ResponsiveImage'
import classes from './FeaturedProductCard.module.scss'
import { useRouter } from 'next/router'
import {
  formatPrice,
  formatShopifyPrice,
  getVariantSubscribedPrice,
} from '@/utils/formatPrice'
import { usePDPDrawerContext } from '@/context/PDPDrawerContext'
// import { getInventory } from "@/utils/getInventory";

const articleCardImgLoader = ({ src }) => {
  return `${src}?w=690`
}

// TODO: make this component reusable. Right now, the product button is hard coded and always links to the product page. It should be given an action and text by the component that is rendering it
function FeaturedProductCard({ product, responsive = false }) {
  const PDPDrawerContext = usePDPDrawerContext()
  const router = useRouter()
  const [mounted, setMounted] = useState(false)
  // const [isAvailable, setIsAvailable] = useState(product.availableForSale);
  // const [selectedVariant, setSelectedVariant] = useState(product.variants[0]);
  const selectedVariant = product.variants[0]

  useEffect(() => {
    setMounted(true)
  }, [])

  // const includesMetafield = product.metafields.find(metafield => metafield.key === 'includes');
  const shortDescriptionMetafield = product.metafields.find(
    (metafield) => metafield.key === 'short_description',
  )
  const flagTag = product.tags.find((tag) =>
    tag.toLowerCase().includes('flag:'),
  )
  const splitFlagTag = flagTag?.split(':')[1]
  const isCuratedSubscription = product.tags.includes('Subscription Box')

  const handleClickSelect = (product) => {
    const isSubscription = product.metafields.some(
      (metafield) => metafield.key === 'subscription_group',
    )
    if (isSubscription) {
      router.push(`/pages/choose-your-plan?expand=${product.content.handle}`)
    } else {
      PDPDrawerContext.openProductDetailDrawer({ productData: product })
    }
  }

  // Products are fetched in the FeaturedProductBlock and not on build, so we don't need to get the inventory again for these products. Uncomment this out if we start using this component in a location where that data is fetched on build.
  // useEffect(() => {
  //   const inventory = await getInventory([{ id: product.variants[0].sourceEntryId, quantity: 1 }])
  //   setIsAvailable(!inventory.inventoryIssues)
  // }, [mounted]);

  return (
    product && (
      <div className={classes['card']}>
        <div className={classes['card__inner']}>
          {splitFlagTag && (
            <div className={`${classes['best-seller']} best-seller`}>
              {splitFlagTag}
            </div>
          )}
          <div className={classes['card__content']}>
            <div className={classes['flex_wrapper']}>
              {product.content.title && (
                <h4
                  className={`${classes['title']} heading--product-title uppercase`}
                >
                  {product.content.title}
                </h4>
              )}
              <div className={classes['price_info']}>
                {selectedVariant.weight} lbs /{' '}
                <span className={classes['price']}>
                  {selectedVariant.compareAtPrice > 0 && (
                    <span className={classes['strikethrough-price']}>
                      ${formatPrice(selectedVariant.compareAtPrice * 100)}
                    </span>
                  )}
                  $
                  {product?.mainPriceOverride
                    ? formatShopifyPrice(product?.mainPriceOverride)
                    : isCuratedSubscription
                      ? getVariantSubscribedPrice({ variant: selectedVariant })
                      : formatShopifyPrice(selectedVariant.price)}
                </span>
              </div>
            </div>
            {/* {<p className={`${classes['metafield']} base-font`}>{includesMetafield?.value}</p>} */}
            {
              <p className={`${classes['metafield']} base-font`}>
                {shortDescriptionMetafield?.value}
              </p>
            }
          </div>
          <a
            onClick={() => handleClickSelect(product)}
            className={classes['media']}
          >
            {product.content.featuredMedia && (
              <Image
                src={product.content.featuredMedia.src}
                alt={
                  product.content.featuredMedia.altText || product.content.title
                }
                fill
                className={classes.image}
                objectFit="cover"
              />
            )}
            {product.content.featuredMedia && responsive && mounted && (
              <ResponsiveImage
                loader={articleCardImgLoader}
                src={product.content.featuredMedia.src}
                alt={
                  product.content.featuredMedia.altText || product.content.title
                }
                className={classes.image}
              />
            )}
          </a>
          <div className={classes['bottom_block']}>
            <a
              onClick={() => handleClickSelect(product)}
              className="btn salmon uppercase"
            >
              Select & Customize
            </a>
          </div>
        </div>
      </div>
    )
  )
}

export default FeaturedProductCard
