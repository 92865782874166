import {
  formatShopifyPrice,
  getVariantSubscribedPrice,
} from '@/utils/formatPrice'
import classes from './ProductHeroPricing.module.scss'
import { useCallback } from 'react'
import { useMarketingContext } from '@/context/MarketingContext'

const ProductHeroPricing = ({
  product,
  selectedVariant,
  hasApplicableDiscount,
  showDiscounts,
  options,
  eligibleDiscounts = [],
  discountEnabledProduct,
  customer,
  onOpenLoginModal,
  quantity = 1,
  pricingDisplay,
}) => {
  const { activeExperiments } = useMarketingContext()
  const [eligibleDiscount] = eligibleDiscounts
  const compareAtPrice = formatShopifyPrice(
    selectedVariant.compareAtPrice * quantity,
  )
  const isCuratedSubscription = product.tags.includes('Subscription Box')
  const basePrice = isCuratedSubscription
    ? getVariantSubscribedPrice({ variant: selectedVariant, quantity })
    : formatShopifyPrice(selectedVariant?.price * quantity)
  const discountedPrice = eligibleDiscount
    ? formatShopifyPrice(
        eligibleDiscount?.getDiscountedPrice(selectedVariant.price * quantity),
      )
    : basePrice

  let pricingDisplayVariant = 'unit'
  if (
    activeExperiments.some(
      (exp) =>
        exp.trigger.includes('pricingSettings') &&
        exp.variant.includes('serveVariant'),
    )
  ) {
    // options are "unit", "serving", "portion"
    pricingDisplayVariant = 'portion' // change this when we decide which one to show
  }

  const productServingInfo = useCallback(() => {
    const price = showDiscounts
      ? parseFloat(discountedPrice)
      : parseFloat(basePrice)
    switch (pricingDisplayVariant) {
      case 'unit':
        if (!pricingDisplay || !pricingDisplay?.quantity) return undefined
        return (
          <div
            className={`${classes['product-hero-pricing--servings']} ${classes[pricingDisplayVariant]}`}
          >
            {`${pricingDisplay.quantity * quantity} ${pricingDisplay.unit}`}
          </div>
        )
      case 'serving':
        if (!pricingDisplay || !pricingDisplay?.servingsQuantity)
          return undefined
        return (
          <div
            className={`${classes['product-hero-pricing--servings']} ${classes[pricingDisplayVariant]}`}
          >
            <span>
              {`${pricingDisplay.servingsQuantity} ${pricingDisplay.servingsUnit}`}
            </span>
            <span>
              (${formatShopifyPrice(price / pricingDisplay.servingsQuantity)}
              /serving)
            </span>
          </div>
        )
      case 'portion':
        if (!pricingDisplay || !pricingDisplay?.portionsQuantity)
          return undefined
        return (
          <div
            className={`${classes['product-hero-pricing--servings']} ${classes[pricingDisplayVariant]}`}
          >
            <span style={{ color: '#ffbb44' }}>
              ${formatShopifyPrice(price / pricingDisplay.portionsQuantity)}
            </span>
            <span style={{ fontSize: '0.6em' }}>per portion</span>
          </div>
        )
    }
  }, [pricingDisplay, pricingDisplayVariant, quantity])

  const renderPriceWrap = useCallback(() => {
    if (!discountEnabledProduct || pricingDisplayVariant === 'portion') {
      return null
    }

    return (
      <div className={classes['product-hero-pricing--login-prompt']}>
        {customer ? renderLoggedInDiscountDisplay() : renderDiscounts()}
      </div>
    )
  }, [hasApplicableDiscount, customer, quantity])

  const renderDiscounts = useCallback(() => {
    if (hasApplicableDiscount) {
      return (
        <p
          data-testid="applicable-discount-price"
          className={`${classes['primary-price']}`}
        >
          with {eligibleDiscount.name}
        </p>
      )
    }

    return (
      <a
        data-testid="discount-login-prompt"
        className={`${classes['secondary-price']}`}
        onClick={onOpenLoginModal}
      >
        Log in for{' '}
        <b>${formatShopifyPrice(selectedVariant.price * quantity * 0.9)}</b>{' '}
        with 10% Member Discount
      </a>
    )
  }, [hasApplicableDiscount, quantity])

  const renderLoggedInDiscountDisplay = useCallback(() => {
    if (hasApplicableDiscount) {
      return <span>with {eligibleDiscount.name}</span>
    }

    return (
      <p
        data-testid="no-applicable-discount-price"
        className={`${classes['secondary-price']}`}
      >
        ${formatShopifyPrice(selectedVariant.price * quantity * 0.9)} with 10%
        Member Discount
      </p>
    )
  }, [hasApplicableDiscount, selectedVariant, quantity])

  return (
    <div className={classes['product-hero-pricing']}>
      <div>
        {(pricingDisplayVariant !== 'portion' ||
          !pricingDisplay?.portionsQuantity) && (
          <>
            {selectedVariant?.compareAtPrice > 0 && (
              <span
                className={`${classes['product-hero-pricing--base']} ${
                  hasApplicableDiscount ? classes['strikethrough'] : ''
                }`}
              >
                ${compareAtPrice}
              </span>
            )}
            <span
              className={`${classes['product-hero-pricing--main']} ${
                hasApplicableDiscount ? classes['strikethrough'] : ''
              }`}
            >
              ${basePrice}
            </span>
            {pricingDisplay && !showDiscounts && !options && (
              <> {productServingInfo()} </>
            )}
          </>
        )}
        {(showDiscounts ||
          (pricingDisplayVariant === 'portion' &&
            !!pricingDisplay?.portionsQuantity)) && (
          <>
            {(pricingDisplayVariant !== 'portion' ||
              !pricingDisplay?.portionsQuantity) && (
              <div>
                <span
                  data-testid="applicable-discount-price"
                  className={`${classes['product-hero-pricing--discounted']}`}
                >
                  ${discountedPrice}
                </span>
              </div>
            )}
            {pricingDisplayVariant === 'portion' &&
              !!pricingDisplay?.portionsQuantity && (
                <div className={`${classes['portion']}`}>
                  <span>
                    <span style={{ color: '#ffffff' }}>
                      {pricingDisplay.portionsQuantity}
                    </span>
                    <span style={{ fontSize: '0.6em' }}>
                      {' '}
                      {pricingDisplay.portionsUnit}
                    </span>
                  </span>
                  <span style={{ fontSize: '0.6em' }}> portions</span>
                </div>
              )}
            {productServingInfo()}
          </>
        )}
      </div>
      {renderPriceWrap()}
    </div>
  )
}

export default ProductHeroPricing
