import classes from './MembershipOptions.module.scss'
import ReactDropdown from 'react-dropdown'
import IconSelectArrow from '@/svgs/select-arrow.svg'
import DualOption from '@/components/DualOption/DualOption'
import RoundedCheckbox from '@/components/RoundedCheckbox/RoundedCheckbox'
import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { formatPrice } from '@/utils/formatPrice'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import { captureMessage } from '@sentry/nextjs'
import FreeShippingProgressBar from '@/components/FreeShippingProgressBar'


const MembershipOptions = ({
  allowPrepaid = false,
  allowOneTime = true,
  options,
  selectedVariants,
  onFrequencyChange,
  servingInfo,
  eligibleDiscounts = [],
  showDiscounts,
  selectedFrequency,
  fullHeight,
  onMembershipTypeChange,
  selectedMembershipType,
  testID,
  cartTotal,
  shippingThreshold,
  hasFreeShippingDiscount,
}) => {
  const [eligibleDiscount] = eligibleDiscounts
  const [isSubscribed, setIsSubscribe] = useState(
    !!selectedMembershipType && selectedMembershipType !== 'one-time-purchase',
  )
  const [frequency, setFrequency] = useState(selectedFrequency)
  const [membershipCost, setMembershipCost] = useState(0)

  const prepaidDiscountAmount = useMemo(() => {
    if (frequency?.toLowerCase() === 'monthly') return '5%'
    else return '3%'
  }, [frequency])

  const isPrepaid =
    !!selectedMembershipType && selectedMembershipType === 'prepaid'

  useEffect(() => {
    if (!allowPrepaid && selectedMembershipType === 'prepaid') {
      captureMessage(
        'Cannot have a prepaid membership type when not allowing prepaid membership',
      )
    }
  }, [allowPrepaid, selectedMembershipType])

  const { oneTimePurchase, subscription } = options

  const sellingPlans = useMemo(
    () =>
      selectedVariants
        .map((v) => v.sellingPlans)
        .flat(1)
        .reduce((plans, plan) => {
          const existing = plans.find((p) => p.name === plan.name)
          if (!existing) {
            plans.push(plan)
          }
          return plans
        }, []),
    [selectedVariants],
  )

  const totalVariantPrice = useMemo(
    () =>
      selectedVariants.reduce((total, v) => total + v.price * v.quantity, 0),
    [selectedVariants],
  )

  const mappedSellingPlans = useMemo(() => {
    return subscription?.availableFrequencies
      ?.map((availableFrequency) => {
        const shopifySubscription = sellingPlans?.find(
          ({ name }) =>
            name.toLowerCase() ===
            availableFrequency.matchingSubscriptionGroup.toLowerCase(),
        )
        if (shopifySubscription) {
          return {
            shopifySubscription,
            availableFrequency,
          }
        }
      })
      .filter((item) => item)
  }, [sellingPlans, subscription.availableFrequencies])

  const getPriceWithDiscount = (price, discount = 0) =>
    price - (price * discount) / 100

  const subscriptionPriceInfo = useMemo(() => {
    if (frequency && mappedSellingPlans && mappedSellingPlans.length > 0) {
      const selectedPlan = mappedSellingPlans.find(
        ({ availableFrequency }) =>
          availableFrequency.matchingSubscriptionGroup.toLowerCase() ===
          frequency,
      )

      const {
        shopifySubscription: { priceAdjustments },
        availableFrequency,
      } = selectedPlan

      const totalDiscount = priceAdjustments
        .map(({ adjustmentValue }) => adjustmentValue.adjustmentPercentage)
        .reduce((accumulator, value) => accumulator + value, 0)

      let price = getPriceWithDiscount(totalVariantPrice, totalDiscount)
      if (isPrepaid) {
        price = getPriceWithDiscount(price, parseInt(prepaidDiscountAmount))
        const numShipments =
          frequency.toLowerCase() === 'every other month' ? 6 : 12
        price = price * numShipments
      }

      return {
        price,
        priceDescriptor: availableFrequency?.pricingDescriptor,
      }
    }
  }, [frequency, isPrepaid, mappedSellingPlans])

  useEffect(() => {
    if (subscriptionPriceInfo?.price) {
      setMembershipCost(subscriptionPriceInfo?.price)
    }
  }, [subscriptionPriceInfo?.price, totalVariantPrice])

  useDeepCompareEffectNoCheck(() => {
    if (mappedSellingPlans?.length) {
      let selectedSellingPlan = mappedSellingPlans.find(
        (plan) =>
          frequency === plan.availableFrequency.matchingSubscriptionGroup,
      )
      if (!selectedSellingPlan) selectedSellingPlan = mappedSellingPlans[0]
      setFrequency(
        selectedSellingPlan.availableFrequency.matchingSubscriptionGroup,
      )
      onFrequencyChange(
        selectedSellingPlan.availableFrequency.matchingSubscriptionGroup,
      )
    }
  }, [mappedSellingPlans])

  const renderPriceBreakdown = (price, pricingDescriptor) => {
    return (
      <div className={classes['membership-options__price-breakdown']}>
        <h6 className={classes['membership-options__price-breakdown__title']}>
          {Intl.NumberFormat('en-US', {
            currency: 'USD',
            style: 'currency',
          }).format(price)}
        </h6>
        {!pricingDescriptor && (
          <span
            className={classes['membership-options__price-breakdown__info']}
          >
            {pricingDescriptor}
          </span>
        )}
        {servingInfo && (
          <span
            className={classes['membership-options__price-breakdown__servings']}
          >
            ~{servingInfo}
          </span>
        )}
      </div>
    )
  }

   const totalWithMembership = useMemo(() => {
    return parseFloat(cartTotal) + parseFloat(membershipCost);
  }, [cartTotal, membershipCost]);

  // Keep membership type (subscription, prepaid, one-time-purchase) up to date when changing different options
  useEffect(() => {
    if (!!onMembershipTypeChange) {
      if (
        isSubscribed &&
        (!selectedMembershipType ||
          selectedMembershipType === 'one-time-purchase')
      ) {
        onMembershipTypeChange('subscription') // Should be subscribe and save when in subscribe and save mode
      } else if (
        !isSubscribed &&
        selectedMembershipType !== 'one-time-purchase'
      ) {
        onMembershipTypeChange('one-time-purchase')
      }
    }
  }, [isSubscribed])

  if (!mappedSellingPlans?.length) {
    console.warn('No mapped selling plans to render Membership Options')
    return null
  }

  return (
    <section
      className={classNames(
        classes['membership-options'],
        fullHeight && classes['membership-options--full-height'],
      )}
      data-testid={testID}
    >
      <div className={classes['membership-options__subscribe__container']}>
        {/* Header */}
        <div className={classes['membership-options__header']}>
          <h4 className={classes['membership-options__subscribe__title']}>
            {subscription.header}
          </h4>
          {allowOneTime && (
            <RoundedCheckbox
              checked={isSubscribed}
              onChange={() => {
                setIsSubscribe(true)
                onFrequencyChange(frequency)
                setMembershipCost(subscriptionPriceInfo?.price)
              }}
            />
          )}
        </div>

        {/* Options */}
        {allowPrepaid && (
          <div className={classes['membership-options__subscribe__options']}>
            <DualOption
              onChangeOption={(e) => {
                setIsSubscribe(true)
                onFrequencyChange(frequency)
                if (!!onMembershipTypeChange) onMembershipTypeChange(e)
              }}
              options={[
                { value: 'subscription', label: 'Monthly' },
                {
                  value: 'prepaid',
                  label: 'Annual',
                  additionalInfo: prepaidDiscountAmount,
                },
              ]}
              defaultOption={selectedMembershipType}
            />
          </div>
        )}

        {/* Price Breakdown */}
        {renderPriceBreakdown(
          subscriptionPriceInfo?.price,
          subscriptionPriceInfo?.priceDescriptor,
        )}
        <ReactDropdown
          className={classNames(
            'dropdown-selector',
            classes['dropdown-no-margin'],
          )}
          options={mappedSellingPlans.map(({ availableFrequency }) => ({
            label: availableFrequency.frequencyOption,
            value: availableFrequency.matchingSubscriptionGroup,
          }))}
          onChange={({ value }) => {
            setIsSubscribe(true)
            setFrequency(value)
            onFrequencyChange(value)
            setMembershipCost(subscriptionPriceInfo?.price)
          }}
          value={frequency}
          arrowClosed={
            <IconSelectArrow className="dropdown-selector__arrow-closed" />
          }
          arrowOpen={
            <IconSelectArrow className="dropdown-selector__arrow-open" />
          }
        />
        <div className={classes['membership-options__text-limiter']}>
          {subscription.valueProps?.map((valueProp) => (
            <p
              className={classes['membership-options__details']}
              key={valueProp}
            >
              {valueProp}
            </p>
          ))}
        </div>
      </div>
      {allowOneTime && (
        <div className={classes['membership-options__one-time__container']}>
          <FreeShippingProgressBar
            total={totalWithMembership}
            shippingThreshold={shippingThreshold}
            hasFreeShippingDiscount={hasFreeShippingDiscount}
          />
          <div className={classes['membership-options__header']}>
            <h4 className={classes['membership-options__one-time__title']}>
              {oneTimePurchase.header}
            </h4>
            <RoundedCheckbox
              checked={!isSubscribed}
              onChange={() => {
                setIsSubscribe(false)
                onFrequencyChange(null)
                setMembershipCost(eligibleDiscount? eligibleDiscount.getDiscountedPrice(totalVariantPrice) : totalVariantPrice)
              }}
            />
          </div>
          {!showDiscounts
            ? renderPriceBreakdown(
                // TODO: write this so it takes into account each variant
                getPriceWithDiscount(totalVariantPrice),
                servingInfo,
              )
            : renderPriceBreakdown(
                // TODO: write this so it takes into account each variant
                formatPrice(
                  eligibleDiscount.getDiscountedPrice(totalVariantPrice) * 100,
                ),
                servingInfo,
              )}
          <div className={classes['membership-options__text-limiter']}>
            <p className={classes['membership-options__details']}>
              {oneTimePurchase.valueProp}
            </p>
          </div>
        </div>
      )}
    </section>
  )
}

export default MembershipOptions
