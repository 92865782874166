import { useEffect } from 'react'
import Image from 'next/image'
import classes from './FeaturedSubscriptionBlock.module.scss'
import { getVariantSubscribedPrice } from '@/utils/formatPrice'
import { usePDPDrawerContext } from '@/context/PDPDrawerContext'
import { useBabDrawerContext } from '@/context/BabDrawerContext'
import imageUrlBuilder from '@sanity/image-url'
import sanityClient from '@/services/sanityClient'
import { usePurchaseFlowContext } from '@/context/PurchaseFlowContext'
import { useRouter } from 'next/router'
import {
  dataLayerViewProductList,
  dataLayerSelectProduct,
} from '@/utils/dataLayer'

const FeaturedSubscriptionBlock = ({ fields }) => {
  const { openDrawer } = usePDPDrawerContext()
  const { openBabPurchaseDrawer } = useBabDrawerContext()
  const { selectSubscriptionBox } = usePurchaseFlowContext()
  const router = useRouter()

  const { header, subscriptionList } = fields
  const [subscriptionOne, subscriptionTwo] = subscriptionList

  const builder = imageUrlBuilder(sanityClient)

  const handleBABButton = (sub) => {
    if (sub.interactionType === 'flyout') {
      openBabPurchaseDrawer()
    } else {
      router.push('/pages/customize-your-plan?babView=1')
    }
  }

  const handleCuratedBoxButton = async (sub) => {
    const { productInfo: product } = sub

    if (sub.interactionType === 'flyout') {
      openDrawer({ productData: product })
    } else {
      selectSubscriptionBox(product)
      router.push('/pages/customize-your-plan')
    }
  }

  useEffect(() => {
    // console.log('track Featured Subscription List')
    dataLayerViewProductList({
      products: subscriptionList
        .filter((p) => !!p.productInfo)
        .map((p) => p.productInfo),
      url: 'Featured Subscription List',
    })
  }, [])

  const renderSubscriptionCard = (subscription) => {
    const [subscriptionTitle, ...subscriptionBulletPoints] =
      subscription.description.split('\n').filter((item) => item)
    const [mainVariant] = subscription.productInfo?.variants || []

    function urlFor(source) {
      return builder.image(source)
    }

    return (
      <div className={classes['featured-subscriptions__option-container']}>
        <Image
          src={urlFor(subscription?.backgroundImage?.asset.url)
            .width(500)
            .height(500)
            .focalPoint(
              subscription?.backgroundImage?.hotspot?.x || 0.5,
              subscription?.backgroundImage?.hotspot?.y || 0.5,
            )
            .crop('focalpoint')
            .fit('crop')
            .url()}
          fill
          alt=""
        />
        <div className={classes['featured-subscriptions__option-info-card']}>
          <h4>{subscription.header}</h4>
          <p>{subscriptionTitle}</p>
          <ul>
            {subscriptionBulletPoints.map((bulletPoint) => (
              <li key={bulletPoint}>{bulletPoint}</li>
            ))}
          </ul>
          {!subscription.babOverride && mainVariant && (
            <div
              className={
                classes[
                  'featured-subscriptions__option-info-card__product-info'
                ]
              }
            >
              <span
                className={
                  classes[
                    'featured-subscriptions__option-info-card__product-info__pricing'
                  ]
                }
              >
                ${getVariantSubscribedPrice({ variant: mainVariant })} / BOX
              </span>
              <span
                className={
                  classes[
                    'featured-subscriptions__option-info-card__product-info__servings'
                  ]
                }
              >
                {
                  mainVariant?.metafields?.find(({ key }) => key === 'servings')
                    ?.value
                }{' '}
                servings
              </span>
            </div>
          )}
          <button
            className={
              classes['featured-subscriptions__option-info-card__action-button']
            }
            onClick={() => {
              if (subscription.babOverride) {
                handleBABButton(subscription)
              } else {
                dataLayerSelectProduct({
                  product: subscription.productInfo,
                  url: router.pathname,
                })
                handleCuratedBoxButton(subscription)
              }
            }}
          >
            {subscription.actionButtonText}
          </button>
          {!subscription.babOverride && !subscription.hideDetails && (
            <button
              onClick={() =>
                openDrawer({ productData: subscription.productInfo })
              }
              className={
                classes['featured-subscriptions__option-info-card__info-button']
              }
            >
              Details & Projected Harvest
            </button>
          )}
        </div>
      </div>
    )
  }

  return (
    <section className={classes['featured-subscriptions']}>
      <h2 className={classes['featured-subscriptions__title']}>{header}</h2>
      {renderSubscriptionCard(subscriptionOne)}
      {renderSubscriptionCard(subscriptionTwo)}
    </section>
  )
}

export default FeaturedSubscriptionBlock
