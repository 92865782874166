import { useEffect } from 'react'
import { useRouter } from 'next/router'
import ProductCollectionCard from '@/components/Cards/ProductCollectionCard/ProductCollectionCard'
import classes from './CollectionHero.module.scss'
import { dataLayerViewProductList } from '@/utils/dataLayer'

const CollectionHero = ({ fields }) => {
  const router = useRouter()
  const {
    heroBackgroundColor: heroBg,
    heroTitle,
    heroDescription,
    mappedProducts,
  } = fields

  useEffect(() => {
    // console.log('track collection hero list', mappedProducts)
    dataLayerViewProductList({ products: mappedProducts, url: router.asPath })
  }, [])

  return (
    <section
      className={classes['collection-hero__container']}
      style={{
        background: `rgba(${heroBg.rgb.r}, ${heroBg.rgb.g}, ${heroBg.rgb.b}, ${heroBg.rgb.a})`,
      }}
    >
      <div className={`${classes['collection-hero__header']} container`}>
        <div role="contentinfo">
          {heroTitle?.heroText && (
            <h1 style={{ color: heroTitle?.headerColor?.hex }}>
              {heroTitle?.heroText}
            </h1>
          )}
          {heroDescription?.descriptionText && (
            <h3 style={{ color: heroDescription?.descriptionColor?.hex }}>
              {heroDescription?.descriptionText}
            </h3>
          )}
        </div>
      </div>

      <div className={`${classes['collection-hero__hero']} container`}>
        {mappedProducts.map(
          ({ hoverFeature, productLocation, ...product }, index) => (
            <ProductCollectionCard
              key={product.handle}
              cardType={index === 0 ? 'hero-main' : 'hero-secondary'}
              as="div"
              product={product}
              title={product.title}
              description={product.description}
              images={product.images}
              location={productLocation}
              hoverFeature={hoverFeature}
              sourceEntryId={product.sourceEntryId}
              sanityImages={product?.sanityData?.productImages}
            />
          ),
        )}
      </div>
    </section>
  )
}

export default CollectionHero
