import { getProductsByHandles } from '@/utils/getProducts'
import classes from './FeaturedProductBlock.module.scss'
import { useEffect, useState } from 'react'
import FeaturedProductCard from '@/components/Cards/FeaturedProductCard'
// Slider Imports
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import { dataLayerViewProductList } from '@/utils/dataLayer'

const FeaturedProductBlock = ({ fields }) => {
  const { headerText, productList } = fields
  const [products, setProducts] = useState([])
  const id = fields?.title?.replace(' ', '-')?.toLowerCase()

  useEffect(() => {
    // console.log('track Featured Product List')
    dataLayerViewProductList({
      products: productList,
      url: 'Featured Product List',
    })
  }, [])

  useEffect(() => {
    getProductsByHandles({
      productHandles: productList.map((el) => el.handle),
    }).then((productData) => setProducts(productData))
  }, [])

  if (!fields) {
    return <></>
  }

  return (
    <div id={id || ''} className={`${classes['featured_block']}`}>
      <div className={classes['featured_block_body']}>
        <div className={classes['featured_block_header-text']}>
          <div>{headerText}</div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              fill="none"
              viewBox="0 0 24 24"
              id="alt-arrow-down"
            >
              <path
                fill="#B7CD96"
                fillRule="evenodd"
                d="M4.43057 8.51192C4.70014 8.19743 5.17361 8.161 5.48811 8.43057L12 14.0122L18.5119 8.43057C18.8264 8.16101 19.2999 8.19743 19.5695 8.51192C19.839 8.82642 19.8026 9.29989 19.4881 9.56946L12.4881 15.5695C12.2072 15.8102 11.7928 15.8102 11.5119 15.5695L4.51192 9.56946C4.19743 9.29989 4.161 8.82641 4.43057 8.51192Z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
        <div className={classes['product-slider']}>
          <div className="swiper-button image-swiper-button-next">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
              id="alt-arrow-down"
            >
              <path
                fill="#003C71"
                fillRule="evenodd"
                d="M4.43057 8.51192C4.70014 8.19743 5.17361 8.161 5.48811 8.43057L12 14.0122L18.5119 8.43057C18.8264 8.16101 19.2999 8.19743 19.5695 8.51192C19.839 8.82642 19.8026 9.29989 19.4881 9.56946L12.4881 15.5695C12.2072 15.8102 11.7928 15.8102 11.5119 15.5695L4.51192 9.56946C4.19743 9.29989 4.161 8.82641 4.43057 8.51192Z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-prev">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
              id="alt-arrow-down"
            >
              <path
                fill="#003C71"
                fillRule="evenodd"
                d="M4.43057 8.51192C4.70014 8.19743 5.17361 8.161 5.48811 8.43057L12 14.0122L18.5119 8.43057C18.8264 8.16101 19.2999 8.19743 19.5695 8.51192C19.839 8.82642 19.8026 9.29989 19.4881 9.56946L12.4881 15.5695C12.2072 15.8102 11.7928 15.8102 11.5119 15.5695L4.51192 9.56946C4.19743 9.29989 4.161 8.82641 4.43057 8.51192Z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <Swiper
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
              disabledClass: 'swiper-button-disabled',
            }}
            modules={[Navigation]}
            slidesPerView={'auto'}
            spaceBetween={15}
            threshold={15}
            breakpoints={{
              1074: {
                spaceBetween: 36,
              },
            }}
          >
            {products.map((product, index) => {
              // if handle doesn't exist, you're probably on the same page of the article you are referencing
              return (
                <SwiperSlide
                  className={classes['product-slide']}
                  key={`${product.id}-${index}-slide`}
                >
                  <FeaturedProductCard
                    product={product}
                    responsive={false}
                    key={`${product.id}-${index}`}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        <div className={classes['divider']} />
      </div>
    </div>
  )
}

export default FeaturedProductBlock
