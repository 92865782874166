import { useEffect, useState } from 'react'
import ResponsiveImage from '@/components/ResponsiveImage'
import Link from 'next/link'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, FreeMode } from 'swiper/modules'
import { getArticlesByHandle } from '@/utils/getArticles'

import DynamicArticleCard from '@/components/Cards/DynamicArticleCard'

import 'swiper/css'
import 'swiper/css/navigation'
import classes from './FeaturedBlogContent.module.scss'
import { DateTime } from 'luxon'

const articleCardImgLoader = ({ src }) => {
  return `${src}?w=750`
}

const FeaturedBlogContent = ({ fields }) => {
  const {
    tabs,
    header,
    subheader,
    ctaUrl,
    ctaText,
    illustration,
    illustration2,
    illustrationAlt,
    illustration2Alt,
    method,
    blog,
    tagList,
    allRecentArticleHandles,
  } = fields
  const [selectedSwiper, setSelectedSwiper] = useState(null)
  const [mounted, setMounted] = useState(false)
  const [validArticles, setValidArticles] = useState([])

  const getArticles = async ({
    fieldTags = [],
    articleHandles,
    forceTagBased = false,
  }) => {
    if (method === 'tagBased' || forceTagBased || method === 'mostRecent') {
      // split ids into batches of 50
      const size = 50
      const batches = []
      for (var i = 0; i < allRecentArticleHandles.length; i += size) {
        batches.push(allRecentArticleHandles.slice(i, i + size))
      }

      // make more queries to get more data for articles
      let allReferences = await batches.reduce(async (carry, batch) => {
        let promises = await carry
        const entries = await getArticlesByHandle(
          batch.map((article) => article.handle),
        )
        if (entries?.length) {
          return [...promises, ...entries]
        } else {
          return [...promises]
        }
      }, Promise.resolve([]))

      // TODO: REVIEW INACCURATE DATE INPUTS ("22-6-05"), not sanity or nacelle issues
      let sortedArticles = allReferences.sort((a, b) => {
        let aDatePublished = a.fields.publishedDate
          ? DateTime.fromISO(a.fields.publishedDate)
          : DateTime.fromISO(a.createdAt)
        let bDatePublished = b.fields.publishedDate
          ? DateTime.fromISO(b.fields.publishedDate)
          : DateTime.fromISO(b.createdAt)
        return bDatePublished > aDatePublished ? 1 : -1
      })

      const filteredArr = sortedArticles
        .filter((article) => article.fields.published)
        .filter((article) => {
          // return most recent based on tags
          if (fieldTags.length && method !== 'mostRecent') {
            return (
              article.fields?.blog?.blogType === blog?.blogType &&
              article.fields?.articleTags?.find((tag) =>
                fieldTags.some(
                  (fieldTag) =>
                    fieldTag.toLowerCase() === tag.value.toLowerCase(),
                ),
              )
            )
          }

          if (fieldTags.length === 0 && method === 'mostRecent') {
            return article.fields?.blog?.title === blog.title
          }

          return article.fields?.blog?.blogType === blog?.blogType
        })
        .slice(0, 4)

      return filteredArr
    } else {
      if (!articleHandles) {
        return []
      }

      const articles = await getArticlesByHandle(articleHandles)

      return articles.filter((article) => article.fields.published)
    }
  }

  useEffect(() => {
    setMounted(true)
    if (method === 'tagBased' || method === 'mostRecent') {
      getArticles({ fieldTags: tagList }).then((articles) => {
        setValidArticles(articles)
      })
    } else {
      const options = {
        articleHandles: tabs[0].tabList,
        fieldTags: tabs[0].tagList,
      }

      if (tabs[0].tagList?.length) {
        options.forceTagBased = true
      }

      getArticles(options).then((articles) => {
        setSelectedSwiper({
          ...tabs[0],
          tabList: articles,
        })
      })
    }
  }, [])

  const filterArticles = (tabName) => {
    const foundTab = tabs.find((tab) => {
      return tab.tabName === tabName
    })

    const options = {
      articleHandles: foundTab.tabList,
      fieldTags: foundTab.tagList,
    }

    if (foundTab.tagList?.length) {
      options.forceTagBased = true
    }

    getArticles(options).then((articles) => {
      setSelectedSwiper({
        ...foundTab,
        tabList: articles,
      })
    })
  }

  return (
    <div className={`${classes['articles']}`}>
      {illustration && (
        <div className={classes['illustration-1']}>
          <ResponsiveImage
            loader={articleCardImgLoader}
            src={illustration.asset.url}
            alt={illustrationAlt || 'illustration'}
          />
        </div>
      )}
      {illustration2 && (
        <div className={classes['illustration-2']}>
          <ResponsiveImage
            loader={articleCardImgLoader}
            src={illustration2.asset.url}
            alt={illustration2Alt || 'illustration'}
          />
        </div>
      )}
      <div className={`${classes['articles__content']}`}>
        <div className={`${classes['articles__header']}`}>
          {header && <h1>{header}</h1>}
          {subheader && <h2>{subheader}</h2>}
        </div>

        {!!selectedSwiper && tabs.length > 1 && method !== 'tagBased' && (
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={40}
            threshold={15}
            modules={[FreeMode]}
            className={`${classes['articles__tabs-swiper']} ${classes['has-swiper']}`}
          >
            {tabs.map((tab) => {
              return (
                <SwiperSlide className={classes['tab-slide']} key={tab.tabName}>
                  <button
                    className={`${
                      tab.tabName === selectedSwiper.tabName
                        ? classes['active']
                        : ''
                    }`}
                    onClick={() => filterArticles(tab.tabName)}
                  >
                    <span>{tab.tabName}</span>
                  </button>
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}

        {mounted && (method === 'tagBased' || method === 'mostRecent') && (
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={18}
            threshold={15}
            navigation={true}
            modules={[Navigation]}
            className={`${classes['articles__swiper']}`}
            breakpoints={{
              1920: {
                slidesPerView: 4,
              },
            }}
          >
            {validArticles.map((article) => {
              return (
                <SwiperSlide
                  className={classes['article-slide']}
                  key={`${article.fields._type}-${article.fields._id}`}
                >
                  <DynamicArticleCard article={article} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}

        {!!selectedSwiper && mounted && method !== 'tagBased' && (
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={18}
            threshold={15}
            navigation={true}
            modules={[Navigation]}
            className={`${classes['articles__swiper']} ${
              classes[tabs.length > 1 ? 'border-top' : '']
            }`}
            breakpoints={{
              1920: {
                slidesPerView: 4,
              },
            }}
          >
            {selectedSwiper.tabList.map((article) => {
              return (
                <SwiperSlide
                  className={classes['article-slide']}
                  key={`${article.fields._type}-${article.fields._id}`}
                >
                  <DynamicArticleCard article={article} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}

        {ctaUrl && (
          <Link href={`${ctaUrl}`} legacyBehavior>
            <a
              className={`${classes['articles__btn']} btn text-align--center no-underline`}
            >
              {ctaText}
            </a>
          </Link>
        )}
      </div>
    </div>
  )
}

export default FeaturedBlogContent
