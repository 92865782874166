import classes from './ProductHeroGiftForm.module.scss'

const ProductHeroGiftForm = ({ onCheck, checked, hasGiftsInCart, cartHasNonGiftItems }) => {
  return (
    <div className={classes['product-hero-gift']}>
      <label htmlFor="product-quantity" className={classes['product-hero-gift__checkbox']}>
        <input
          type="checkbox"
          id="product-quantity"
          onChange={onCheck}
          checked={checked}
          disabled={cartHasNonGiftItems || hasGiftsInCart}
        />
        This is a gift
      </label>
        {checked && !hasGiftsInCart && (
          <p className={classes['tooltiptext']}> <br />By adding a gift to your cart, it will turn everything in your cart into a gift order.</p>
        )}
        {checked && hasGiftsInCart && (
          <p className={classes['tooltiptext']}> <br />You already have gifts in your cart, all items will be added as gifts .</p>
        )}
        {cartHasNonGiftItems && (
          <p className={classes['tooltiptext']}> <br />You have a non-gift subscription in your cart. Please remove it before adding a gift.  </p>
        )}
    </div>
  )
}

export default ProductHeroGiftForm
