import { useState, useEffect } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import IconArrow from '@/svgs/arrow-right.svg'

import classes from './RecipeCategoriesList.module.scss'
import ResponsiveComponent from '@/components/ResponsiveComponent'

const RecipeCategoriesList = ({ fields }) => {
  const { header, ctaUrl, ctaText, categoriesList } = fields
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [fields])

  return (
    <div className={`${classes['categories']}`}>
      <div className="container">
        <div className={classes['categories__header']}>
          {header && <h1>{header}</h1>}

          {ctaUrl && (
            <div className={classes['categories__header-link']}>
              <Link href={ctaUrl}>{ctaText}</Link>
              <IconArrow />
            </div>
          )}
        </div>
        <div className={classes['categories__list']}>
          {categoriesList.length > 0 &&
            categoriesList.map((category) => {
              if (!category.url) {
                return <></>
              }
              return (
                <Link href={category.url} key={category._key} legacyBehavior>
                  <ResponsiveComponent
                    component="a"
                    className={classes['categories__card']}
                    desktopBreakpoint={'xs'}
                    mobile={
                      <>
                        {category.desktopImage.asset.url && (
                          <div className={`${classes['card__img']}`}>
                            <Image
                              src={category.desktopImage.asset.url}
                              alt={category.alt || 'category image'}
                              fill
                              objectFit="cover"
                            />
                          </div>
                        )}
                        {category?.header && <h4>{category.header}</h4>}
                      </>
                    }
                    desktop={
                      <>
                        {category.desktopImage.asset.url && (
                          <div className={`${classes['card__img']}`}>
                            <Image
                              src={category.desktopImage.asset.url}
                              alt={category.alt || 'category image'}
                              fill
                              objectFit="cover"
                            />
                          </div>
                        )}
                        {category?.header && <h4>{category.header}</h4>}
                      </>
                    }
                  />
                </Link>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default RecipeCategoriesList
