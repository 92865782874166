import ContentSections from "../ContentSections";
import classes from './ColumnSection.module.scss'

export const ColumnSection = ({columns}) => {
  return (
    <div className={classes['column_section']}>
      {columns.map(col => {
        return (
          <div key={col._key} style={{'width': col.colWidth ? `${col.colWidth}%` : null}}>
            <ContentSections sections={col.content}></ContentSections>
          </div>
        )
      })}
    </div>
  )
}