import classes from './FullBleedVideo.module.scss'
import useScreenSize from '@/hooks/useScreenSize'
import { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import Youtube from 'react-youtube'
import Typography from '@/components/Typography/Typography'
import Button from '@/components/Button/Button'

const mappedItems = {
  right: 'flex-end',
  left: 'flex-start',
  center: 'center',
}
const FullBleedVideo = ({ fields }) => {
  const {
    youtubeVideoId,
    buttons = [],
    itemsPosition,
    spaceBetweenElements,
    textList,
    buttonsArrangement,
  } = fields

  const { height, width } = useScreenSize()
  const smallDevice = useMediaQuery({ query: '(max-width: 800px)' })
  const extraSmallDevice = useMediaQuery({ query: '(max-width: 500px)' })

  const onEnd = (event) => {
    event.target.playVideo()
  }

  const playerWidth = useMemo(() => {
    if (extraSmallDevice) {
      return width * 3
    }

    if (smallDevice) {
      return width * 2
    }

    return width
  }, [smallDevice, smallDevice, width, height])

  const opts = {
    height: height,
    width: playerWidth,
    playerVars: {
      autoplay: 1,
      rel: 1,
      disablekb: 0,
      loop: 1,
      controls: 0,
      showinfo: 0,
      listType: 0,
      playsinline: 0,
      mute: 1,
    },
  }

  return (
    <div className={classes['full-bleed-video-container']}>
      <Youtube
        videoId={youtubeVideoId}
        opts={opts}
        onEnd={(e) => onEnd(e)}
        className={classes['full-bleed-video-container__video']}
      />
      <div
        className={classes['full-bleed-video-container__content']}
        style={{
          alignItems: mappedItems[itemsPosition],
          gap: spaceBetweenElements,
        }}
      >
        {textList.map((props, i) => (
          <Typography
            key={`${i}-full-bleed-${props.text}`}
            as={i === 0 ? 'h2' : 'h3'}
            {...props}
          >
            {props.text}
          </Typography>
        ))}
        {buttons?.length > 0 && (
          <div
            className={classes['full-bleed-video-container__buttons-container']}
            style={{
              flexDirection: buttonsArrangement ? buttonsArrangement : 'row',
            }}
          >
            {buttons.map((props, i) => (
              <Button
                key={`${i}-full-bleed-${props.text}`}
                as="link"
                {...props}
              >
                {props.text}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default FullBleedVideo
