import GenericContainer from '../GenericContainer'
import Typography from '@/components/Typography'
import List from '@/components/List'
import Button from '@/components/Button'
import Image from '@/components/Image'

const GenericComponent = ({ _type, ...props }) => {
  switch (_type) {
    case 'genericContainer':
      return <GenericContainer {...props} />
    case 'genericImage':
      // This is disabled because it's already being passed along with these props down
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Image {...props} />
    case 'typography':
      return <Typography {...props}>{props.text}</Typography>
    case 'button':
      return <Button {...props}>{props.text}</Button>
    case 'list':
      return <List {...props} />
    default:
      return null
  }
}

export default GenericComponent
