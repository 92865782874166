import { useMediaQuery } from 'react-responsive'
import classes from './GenericContainer.module.scss'
import { useMemo } from 'react'
import classNames from 'classnames'
import GenericComponent from '../GenericComponent'

const spaceMapping = {
  extraSmall: '12px',
  small: '20px',
  medium: '30px',
  Large: '40px',
}

const verticalAlignmentMap = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
}

const horizontalAlignmentMap = {
  center: 'center',
  top: 'flex-start',
  bottom: 'flex-end',
}

/**
 * A flexible container component that arranges its children based on specified dimensions, alignment, and spacing.
 * @module GenericContainer
 * @param {Object} props - The component props.
 * @param {string} [props.containerDimension] - The dimension (width or height depending on the device type) of the container.
 * @param {React.ReactNode} props.children - The children elements to be rendered within the container.
 * @param {'row' | 'column'} [props.itemsArrangement='row'] - The arrangement of items within the container, either 'row' (default) or 'column'.
 * @param {'small' | 'medium' | 'large'} [props.spaceBetweenElements='medium'] - The spacing between elements within the container.
 * @param {'center' | 'left' | 'right'} [props.verticalAlignment] - The vertical alignment of items within the container.
 * @param {'center' | 'top' | 'bottom'} [props.horizontalAlignment] - The horizontal alignment of items within the container.
 * @param {object} [props.backgroundColor] - A Sanity object which defines the colors.
 * @param {boolean} [props.noPadding=false] - If true, removes padding from the container.
 * @returns {React.ReactElement} The rendered GenericContainer component.
 */
const GenericContainer = ({
  containerDimension,
  children,
  itemsArrangement,
  spaceBetweenElements,
  verticalAlignment,
  horizontalAlignment,
  noPadding,
  backgroundColor,
  components,
  _key,
  mainContainer,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

  const dimensionStyles = useMemo(() => {
    if (isMobile && mainContainer) {
      return { height: '100%' }
    }

    if (containerDimension) {
      return { width: `${containerDimension}%` }
    }

    return {}
  }, [isMobile, containerDimension])

  const directionStyles = useMemo(() => {
    if (!verticalAlignment && !horizontalAlignment) {
      return {}
    }

    if (itemsArrangement === 'column') {
      return {
        alignItems: verticalAlignmentMap[verticalAlignment],
        justifyContent: horizontalAlignmentMap[horizontalAlignment],
      }
    }

    return {
      justifyContent: verticalAlignmentMap[verticalAlignment],
      alignItems: horizontalAlignmentMap[horizontalAlignment],
    }
  }, [itemsArrangement, verticalAlignment, horizontalAlignment])

  return (
    <div
      className={classNames(
        classes['generic-container'],
        noPadding && classes['generic-container--no-padding'],
      )}
      style={{
        ...dimensionStyles,
        ...directionStyles,
        flexDirection: itemsArrangement,
        gap: spaceMapping[spaceBetweenElements],
        background:
          backgroundColor &&
          `rgba(${backgroundColor.rgb.r}, ${backgroundColor.rgb.g}, ${backgroundColor.rgb.b}, ${backgroundColor.rgb.a})`,
      }}
    >
      {components.map((componentProps, i) => (
        <GenericComponent key={`${_key}-component-${i}`} {...componentProps} />
      ))}
      {children}
    </div>
  )
}

export default GenericContainer
