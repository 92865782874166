import { useState, useEffect } from 'react'
import Image from 'next/image'

import classes from './BlogHero.module.scss'
import ResponsiveComponent from '@/components/ResponsiveComponent'

const BlogHero = ({ fields, imagePriority }) => {
  const [mounted, setMounted] = useState(false)
  let {
    title,
    heroStyle,
    textColor,
    desktopBackgroundImage,
    mobileBackgroundImage,
    topMargin,
    bottomMargin,
    alt,
  } = fields
  let btnColor

  useEffect(() => {
    setMounted(true)
  }, [fields])

  // Conditionally change the color of the button
  if (textColor === 'text--light') {
    btnColor = 'alabaster'
  } else {
    btnColor = 'salmon'
  }

  return (
    <div
      className={`${classes['hero']} ${classes[heroStyle]} ${classes[textColor]} ${topMargin ? classes['top-margin'] : ''} ${bottomMargin ? classes['bottom-margin'] : ''}`}
    >
      <div className={`${classes['hero__text']}`}>
        <div className={classes['hero__text-inner']}>
          {fields.header && <h1>{fields.header}</h1>}
          {fields.subheader && <h2>{fields.subheader}</h2>}
        </div>
      </div>

      <ResponsiveComponent
        desktopBreakpoint={'sm'}
        mobile={
          mobileBackgroundImage.asset.url && (
            <div
              className={`${classes['hero__wrap']} ${classes['hero__wrap--mbl']}`}
            >
              <Image
                className={classes.dsktp__img}
                src={mobileBackgroundImage.asset.url}
                layout="fill"
                priority={imagePriority}
                alt={alt || title}
              />
            </div>
          )
        }
        desktop={
          desktopBackgroundImage?.asset?.url && (
            <div
              className={`${classes['hero__wrap']} ${classes['hero__wrap--dsktp']}`}
            >
              <Image
                className={classes.mbl__img}
                src={desktopBackgroundImage.asset.url}
                layout="fill"
                priority={imagePriority}
                alt={alt || title}
              />
            </div>
          )
        }
      />
    </div>
  )
}

export default BlogHero
