import classNames from 'classnames'
import classes from './Image.module.scss'
import NextImage from 'next/image'
import useImage from '@/hooks/useImage'

const Image = ({ variant, alt, image }) => {
  const imageUrl = useImage(image)
  return (
    <div
      className={classNames(
        classes['generic-image-container'],
        variant === 'primaryGradient' &&
          classes['generic-image--primary-gradient'],
        variant === 'horizontalGradient' &&
          classes['generic-image--horizontal-gradient'],
        variant === 'verticalGradient' &&
          classes['generic-image--vertical-gradient'],
        variant === 'blurredGradient' &&
          classes['generic-image--blurred-gradient'],
      )}
    >
      <NextImage
        alt={alt}
        className={classNames(
          (variant === 'blurred' || variant === 'blurredGradient') &&
            classes['generic-image--blurred'],
          variant === 'rounded' && classes['generic-image--rounded'],
        )}
        layout="fill"
        objectFit="cover"
        src={imageUrl}
      />
    </div>
  )
}

export default Image
