const colors = {
  black: '#2D2B26',
  black60: '#797875',
  black20: '#D5D5D4',
  black10: '#EAEAE9',
  fawn: '#EAE0D2',
  fawn50: '#F6F3ED;',
  fawn40: '#FCFAF8',
  alabaster: '#FFFDFC',
  sitkaBlue: '#163144',
  sitkaBlueLight: '#3C597D',
  copper: '#AF5D2F',
  salmon: '#D18357',
  salmon30: '#E7D4C8',
  green: '#467C52',
  green2: '#B7CD96',
  review: '#FFBB44',
  success: '#467C52',
  caution: '#D8A82D',
  error: '#C04A30',
  white: '#fff',
}

export default colors
