import { useState, useEffect } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { Swiper, SwiperSlide } from 'swiper/react'

import DynamicArticleCard from '@/components/Cards/DynamicArticleCard'
import IconLongArrow from '@/svgs/long-arrow-right.svg'
import IconArrow from '@/svgs/arrow-right.svg'

import 'swiper/css'
import classes from './HalfHeroHalfSlider.module.scss'
import ResponsiveComponent from '@/components/ResponsiveComponent'

const HalfHeroHalfSlider = ({ fields, imagePriority }) => {
  const {
    header,
    ctaUrl,
    ctaText,
    desktopImage,
    mobileImage,
    imageAlt,
    imageHeader,
    imageCtaText,
    imageCtaUrl,
    mobileButtonText,
    articles,
  } = fields
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [fields])

  return (
    <div className={classes['wrapper']}>
      <div className="">
        <div className={`${classes['header']} container`}>
          {header && <h1>{header}</h1>}

          {ctaUrl && (
            <div className={classes['header-link']}>
              <Link href={ctaUrl}>{ctaText}</Link>
              <IconArrow />
            </div>
          )}
        </div>

        <div className={classes['row']}>
          <div className={classes['hero-wrap']}>
            <ResponsiveComponent
              desktopBreakpoint={'sm'}
              className={classes['hero']}
              mobile={
                mobileImage && (
                  <>
                    <Image
                      src={mobileImage.asset.url}
                      layout="fill"
                      priority={imagePriority}
                      alt={imageAlt || 'hero image'}
                      objectFit="cover"
                    />
                    <div className={classes['hero-wrap__text']}>
                      {imageHeader && <h1>{imageHeader}</h1>}
                      {imageCtaUrl && (
                        <Link href={imageCtaUrl} legacyBehavior>
                          <a className={classes['subheader-link']}>
                            <span>{imageCtaText}</span>
                            <span><IconLongArrow style={{ color: '#fff', width: 44, height: 18 }}/></span>
                          </a>
                        </Link>
                      )}
                      {imageCtaUrl && (
                        <div className={classes['mbl-btn']}>
                          <Link href={imageCtaUrl} legacyBehavior>
                            <a className="btn sitkablue">{mobileButtonText}</a>
                          </Link>
                        </div>
                      )}
                    </div>
                  </>
                )
              }
              desktop={
                desktopImage && (
                  <>
                    <Image
                      sizes="50vw"
                      src={desktopImage.asset.url || 'hero image'}
                      layout="fill"
                      priority={imagePriority}
                      alt={imageAlt}
                      objectFit="cover"
                    />
                    <div className={classes['hero-wrap__text']}>
                      {imageHeader && <h1>{imageHeader}</h1>}
                      {imageCtaUrl && (
                        <Link href={imageCtaUrl} legacyBehavior>
                          <a className={classes['subheader-link']}>
                            <span>{imageCtaText}</span>
                            <span>
                              <IconLongArrow />
                            </span>
                          </a>
                        </Link>
                      )}
                      {imageCtaUrl && (
                        <div className={classes['mbl-btn']}>
                          <Link href={imageCtaUrl} legacyBehavior>
                            <a className="btn sitkablue">{mobileButtonText}</a>
                          </Link>
                        </div>
                      )}
                    </div>
                  </>
                )
              }
            />
          </div>

          <ResponsiveComponent
            desktopBreakpoint={'sm'}
            className={classes['slider']}
            display="flex"
            mobile={
              articles.length > 0 && (
                <Swiper
                  loop={true}
                  slidesPerView={'auto'}
                  spaceBetween={18}
                  threshold={15}
                  centeredSlides={true}
                >
                  {articles.map((article) => {
                    return (
                      <SwiperSlide
                        className={classes['slider__slide']}
                        key={`${article._type}-${article._id}`}
                      >
                        <DynamicArticleCard article={article} />
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              )
            }
            desktop={
              articles.length > 0 &&
              articles.map((article) => {
                return (
                  <div
                    className={classes['slider__slide']}
                    key={`${article._type}-${article._id}`}
                  >
                    <DynamicArticleCard article={article} />
                  </div>
                )
              })
            }
          />
        </div>
      </div>
    </div>
  )
}

export default HalfHeroHalfSlider
