import classes from './ProductHero.module.scss'
import { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import useScrollPercentage from '@/hooks/useScrollPercentage'
import { useMediaQuery } from 'react-responsive'
import { Parallax } from 'react-parallax'
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'
import { useCustomerContext } from '@/context/CustomerContext'
import { useModalContext } from '@/context/ModalContext'
import { getSelectedVariant } from '@/utils/getSelectedVariant'
import imageUrlBuilder from '@sanity/image-url'
import sanityClient from '@/services/sanityClient'
import useProductReviewsDetails from '@/hooks/useProductReviewsDetails'
import ProductHeroHeader from './ProducHeroHeader'
import ProductHeroPricing from './ProductHeroPricing'
import useProductDiscountsInfo from '@/hooks/useProductDiscountsInfo'
import Options from '@/components/Options/Options'
import ProductHeroQuantityButton from './ProductHeroQuantityButton'
import ProductHeroGiftForm from './ProductHeroGiftForm'
import ProductHeroImages from './ProductHeroImages'
import MembershipOptions from '@/components/PurchaseFlow/MembershipOptions'
import FreeShippingProgressBar from '@/components/FreeShippingProgressBar'

const ProductHero = ({
  product,
  fields,
  selectedVariant,
  setSelectedVariant,
  productPurchaseOptions,
}) => {
  const { productLocation, productInfoImages } = fields
  const availableForSale = selectedVariant?.availableForSale

  // Pricing Display Experiment

  const variants = product.variants

  const [giftFormFields, setGiftFormFields] = useState({})
  const [selectedOptions, setSelectedOptions] = useState(
    selectedVariant.content?.selectedOptions,
  )
  const [membershipFrequency, setMembershipFrequency] = useState()
  const { recipient_name, recipient_email } = giftFormFields
  const isCuratedSubscription = product.tags.includes('Subscription Box')
  console.log('isCuratedSubscription', isCuratedSubscription)
  const { customer } = useCustomerContext()
  const productReviewInfo = useProductReviewsDetails(product?.handle?.current)

  const {
    hasApplicableCustomerDiscount,
    hasApplicableDiscount,
    hasApplicableNonCustomerDiscount,
    discountEnabledProduct,
  } = useProductDiscountsInfo(product, customer)

  const modalContext = useModalContext()
  const {
    data,
    addItemToOrder,
    updateGiftOptionMetadata,
    getEligibleDiscounts,
    allSubsInCartAreGiftable,
  } = useHeadlessCheckoutContext()

  const openLoginModal = (e) => {
    e.preventDefault()
    modalContext.setIsOpen(false)
    modalContext.setModalType('login-noredirect')
    modalContext.setIsOpen(true)
  }

  const [quantity, setQuantity] = useState(1)
  const [isGift, setIsGift] = useState(false)
  console.log('isGift in product hero', isGift)
  const [isMobile, setIsMobile] = useState(true)
  const [scrollVariant, setScrollVariant] = useState(true)
  const [isSubscribeAndSave, setIsSubscribeAndSave] = useState(
    isCuratedSubscription,
  )

  const membershipType = isSubscribeAndSave
    ? 'subscribe-and-save'
    : 'one-time-purchase'

  const mobileMedia = useMediaQuery({ query: '(max-width: 767px)' })

  const percentage = useScrollPercentage()

  const flagTag = product?.tags?.find((tag) =>
    tag.toLowerCase().includes('flag:')
  )
  const productTag = flagTag?.split(':')[1]

  const eligibleDiscounts = useMemo(
    () => getEligibleDiscounts({ lineItems: [{ ...product }] }),
    [customer, product],
  )
  const [eligibleDiscount] = eligibleDiscounts

  const [membershipCost, setMembershipCost] = useState(selectedVariant?.price)
  useEffect(() => {
    if(eligibleDiscounts.length > 0) {
      setMembershipCost(eligibleDiscount?.getDiscountedPrice(selectedVariant?.price))
    }
  }
  , [eligibleDiscounts])

  const showDiscounts =
    hasApplicableCustomerDiscount || hasApplicableNonCustomerDiscount
  const showSubscribeAndSave =
    !isCuratedSubscription &&
    !scrollVariant &&
    productPurchaseOptions &&
    selectedVariant?.sellingPlans &&
    selectedVariant?.sellingPlans.length > 0

  const handleGiftInfo = (event) => {
    const { name, value } = event.target
    setGiftFormFields({ ...giftFormFields, [name]: value })
  }

  useEffect(() => {
    const recipient_name = data?.cart?.properties?.recipient_name
    const recipient_email = data?.cart?.properties?.recipient_email
    // make updates if cart is gift and PDP is not displaying gift info
    if (!isGift && (!!recipient_name || !!recipient_email)) {
      const newFormFields = { ...giftFormFields }
      if (recipient_name) newFormFields.recipient_name = recipient_name
      if (recipient_email) newFormFields.recipient_email = recipient_email
      setGiftFormFields(newFormFields) // set recipient info so it is show in UI
      setIsGift(true) // display gift option to user
    }
  }, [data?.cart?.properties])

  const options = useMemo(() => {
    if (product?.content?.options?.some((option) => option.values.length > 1)) {
      return product?.content?.options
    }
    return null
  }, [product])

  const handleOptionChange = (event, option) => {
    const newOption = { name: option.name, value: event.target.value }
    const optionIndex = selectedOptions.findIndex((selectedOption) => {
      return selectedOption.name === newOption.name
    })

    const newSelectedOptions = [...selectedOptions]
    if (optionIndex > -1) {
      newSelectedOptions.splice(optionIndex, 1, newOption)
      setSelectedOptions([...newSelectedOptions])
    } else {
      setSelectedOptions([...newSelectedOptions, newOption])
    }
    const variant = getSelectedVariant({
      variants,
      options: newSelectedOptions,
    })
    setSelectedVariant(variant ? { ...variant } : null)
  }

  const { title, description } = product

  const mainImage = product?.imagesFromSanity[0].productImage
  const builder = imageUrlBuilder(sanityClient)
  function urlFor(source) {
    return builder.image(source)
  }

  useEffect(() => {
    if (!isMobile) {
      setScrollVariant(percentage > 10 ? true : false)
    }
  }, [percentage, isMobile])

  const addToCart = useCallback(async () => {
    let properties = {}

    if (isCuratedSubscription) {
      properties = {
        interval_text: membershipFrequency,
        membership_type: 'regular',
      }
    }

    if (membershipFrequency) {
      properties = {
        interval_text: membershipFrequency,
        membership_type: 'SnS', // denotes specific subscribe and save subscription purchase
      }
    }

    await addItemToOrder({
      variant: selectedVariant,
      quantity,
      properties,
      product,
      redirectToCart: true,
    })
    if (isGift){
      await updateGiftOptionMetadata(giftFormFields)} 
  }, [selectedVariant, membershipFrequency, quantity, isGift, giftFormFields, updateGiftOptionMetadata])

  // Workaround because react-responsive doesn't work well with SSR
  useEffect(() => setIsMobile(mobileMedia), [mobileMedia])
  useEffect(() => {
    if (isMobile) {
      setScrollVariant(false)
    }
  }, [isMobile])

  const hasOnlyIntroBox = data?.cart?.lineItems.every(
    (li) => li.merchandise?.product?.handle === 'sitka-seafood-intro-box',
  )

  const cartTotal = data?.cart?.cost?.totalAmount?.amount

   const hasFreeShippingCode = data?.cart?.discountCodes.some(
    (d) => d.applicable && d.code === 'Ship with Next Order',
  )

  let shippingCost
  if (!hasFreeShippingCode) {
    const shippingCostArray = data?.cart?.deliveryGroups.nodes.map((group) =>
      parseFloat(group.selectedDeliveryOption?.estimatedCost.amount),
    )
    shippingCost =
      shippingCostArray?.length > 0
        ? shippingCostArray.reduce((total, cost) => (total += cost), 0)
        : NaN
  } else {
    shippingCost = 0
  }

  const hasFreeShippingDiscount = (!isNaN(shippingCost) && cartTotal > 100 && hasOnlyIntroBox)

  const hasFreeShipping = hasFreeShippingDiscount || hasFreeShippingCode

  const shippingThreshold = 100

  const totalWithMembership = useMemo(() => {
    return parseFloat(cartTotal) +( parseFloat(membershipCost) * quantity);
  }, [cartTotal, membershipCost, quantity]);

  const hasGiftsInCart = data?.cart?.properties?.is_gift_order === 'true'
  const isGiftOrder = isGift || hasGiftsInCart

  useEffect(() => {
    if (isGiftOrder && isSubscribeAndSave) {
      setIsSubscribeAndSave(false);
      setMembershipFrequency(null);
    }
  }, [isGiftOrder, isSubscribeAndSave]);

  return (
    <>
      {isMobile && (
        <div className={classNames(classes['product-hero__description'])}>
          <b>Description:</b>
          <p>{description}</p>
        </div>
      )}
      <Parallax
        strength={-300}
        blur={{ min: 0, max: 0 }}
        bgImage={urlFor(mainImage.asset.url)
          .width(1000)
          .height(1000)
          .focalPoint(
            mainImage?.hotspot?.x || 0.5,
            mainImage?.hotspot?.y || 0.5,
          )
          .crop('focalpoint')
          .fit('crop')
          .url()}
        bgImageAlt={mainImage.alt}
        contentClassName={classes['product-hero__parallax']}
      >
        <section
          className={classNames(
            classes['product-hero'],
            isSubscribeAndSave && isMobile && classes['product-hero--expanded'],
          )}
        >
          {isMobile && (
            <ProductHeroHeader
              isMobile={isMobile}
              productLocation={productLocation}
              productReviewInfo={productReviewInfo}
              productTag={productTag}
              title={title}
            />
          )}

          <div
            className={classNames(
              classes['product-hero__info'],
              scrollVariant && classes['product-hero__info--fixed'],
              isSubscribeAndSave &&
                isMobile &&
                classes['product-hero__info--mobile'],
            )}
          >
            <div
              className={classNames(
                classes['product-hero__info__container'],
                scrollVariant &&
                  classes['product-hero__info__container--fixed'],
              )}
            >
              {!scrollVariant && isSubscribeAndSave && isMobile && !hasGiftsInCart && !isGift && (
                <MembershipOptions
                  allowPrepaid={false}
                  allowOneTime={true}
                  eligibleDiscounts={eligibleDiscounts}
                  options={productPurchaseOptions}
                  selectedVariants={[{ ...selectedVariant, quantity }]}
                  showDiscounts={showDiscounts}
                  onFrequencyChange={(newFrequency) =>
                    setMembershipFrequency(newFrequency)
                  }
                  selectedMembershipType={membershipType}
                  cartTotal={cartTotal}
                  shippingThreshold={shippingThreshold}
                  hasFreeShippingDiscount={hasFreeShipping}
                  testID="membership-options-mobile"
                />
              )}
              {!isMobile && (
                <ProductHeroHeader
                  isMobile={isMobile}
                  productLocation={productLocation}
                  productReviewInfo={productReviewInfo}
                  productTag={productTag}
                  title={title}
                  scrollVariant={scrollVariant}
                  description={description}
                />
              )}

              <ProductHeroPricing
                product={product}
                discountEnabledProduct={discountEnabledProduct}
                onOpenLoginModal={openLoginModal}
                eligibleDiscounts={eligibleDiscounts}
                hasApplicableDiscount={hasApplicableDiscount}
                options={options}
                showDiscounts={showDiscounts}
                selectedVariant={selectedVariant}
                quantity={quantity}
                pricingDisplay={product.pricingDisplay}
              />
              <FreeShippingProgressBar
                total={totalWithMembership}
                shippingThreshold={shippingThreshold}
                hasFreeShippingDiscount={hasFreeShippingDiscount}
                isMembership={false}
                hasSuscribeAndSave={isSubscribeAndSave}
          />
              {!hasGiftsInCart && !isGift && showSubscribeAndSave && (
                <Options secondary>
                  <Options.Item
                    name="purchase-options"
                    label="Subscribe & Save"
                    checked={isSubscribeAndSave}
                    onChange={() => setIsSubscribeAndSave(true)}
                    value="subscribe-and-save"
                  />
                  <Options.Item
                    name="purchase-options"
                    label="One-Time Purchase"
                    checked={!isSubscribeAndSave}
                    onChange={() => {
                      setIsSubscribeAndSave(false)
                      setMembershipFrequency(null)
                    }}
                    value="one-time-purchase"
                  />
                </Options>
              )}

              {options &&
                options.length > 0 &&
                !scrollVariant &&
                options.map((option, i) => (
                  <Options key={option.name}>
                    {option.values.map((value) => (
                      <Options.Item
                        name={`product-options-${i}`}
                        key={value}
                        label={option.name === 'amount' ? `$${value}` : value}
                        onChange={($event) =>
                          handleOptionChange($event, option)
                        }
                        value={value}
                        checked={
                          selectedVariant.content.selectedOptions[0].value ===
                          value
                        }
                      />
                    ))}
                  </Options>
                ))}

              <ProductHeroQuantityButton
                onDecrease={() => setQuantity(quantity > 0 ? quantity - 1 : 0)}
                onIncrease={() => setQuantity(quantity + 1)}
                buttonText={!availableForSale ? 'Sold Out' : 'Add to Cart'}
                onChange={(event) => setQuantity(event.target.value)}
                buttonDisabled={quantity === 0}
                availableForSale={availableForSale}
                onClickButton={addToCart}
                value={quantity}
              />

              <ProductHeroGiftForm
                onCheck={() => setIsGift(!isGift)}
                checked={isGift || hasGiftsInCart}
                onChangeInput={handleGiftInfo}
                recipientEmail={recipient_email}
                recipientName={recipient_name}
                hasGiftsInCart={hasGiftsInCart}
                cartHasNonGiftItems={!allSubsInCartAreGiftable}
              />

              {!scrollVariant && !isMobile && (
                <ProductHeroImages
                  assets={productInfoImages.map(({ asset }) => asset)}
                />
              )}
            </div>
            {!scrollVariant && isSubscribeAndSave && !isMobile && !hasGiftsInCart && !isGift &&(
              <MembershipOptions
                allowPrepaid={false}
                allowOneTime={true}
                eligibleDiscounts={eligibleDiscounts}
                options={productPurchaseOptions}
                selectedVariants={[{ ...selectedVariant, quantity }]}
                showDiscounts={showDiscounts}
                onFrequencyChange={(newFrequency) =>
                  setMembershipFrequency(newFrequency)
                }
                selectedMembershipType={membershipType}
                testID="membership-options-desktop"
                cartTotal={cartTotal}
                shippingThreshold={shippingThreshold}
                hasFreeShippingDiscount={hasFreeShipping}
                isGiftOrder={isGiftOrder}
              />
            )}
          </div>
        </section>
      </Parallax>
    </>
  )
}

export default ProductHero
