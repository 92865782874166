import classes from "./ProductHeroQuantityButton.module.scss";

const ProductHeroQuantityButton = ({
  onIncrease,
  onDecrease,
  value,
  buttonText,
  onChange,
  buttonDisabled,
  onClickButton,
  availableForSale,
}) => {
  return (
    <div className={classes["product-hero-quantity-button"]}>
      {availableForSale && (
        <div className={classes["product-hero-quantity-button__quantity"]}>
          <button
            className={
              classes["product-hero-quantity-button__quantity__button"]
            }
            onClick={onDecrease}
          >
            -
          </button>
          <input
            type="number"
            className={classes["product-hero-quantity-button__quantity__input"]}
            value={value}
            onChange={onChange}
          />
          <button
            className={
              classes["product-hero-quantity-button__quantity__button"]
            }
            onClick={onIncrease}
          >
            +
          </button>
        </div>
      )}
      <button
        className="btn salmon"
        disabled={!availableForSale || buttonDisabled}
        onClick={onClickButton}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default ProductHeroQuantityButton;
