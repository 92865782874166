import sanityClient from '../services/sanityClient'

export const getCatchDataByHandles = async (handles) => {
  const theCatches = await sanityClient.fetch(
    GET_CATCH_DATA_BY_HANDLES(handles),
  )
  const theCatchData = theCatches.map((theCatch) => {
    return {
      createdAt: theCatch._createdAt,
      fields: {
        __typename: theCatch._type,
        ...theCatch,
      },
      handle: theCatch.handle.current,
      published: true,
      sourceEntryId: '',
      sourceId: theCatch.objectID,
      tags: [],
      title: theCatch.title,
      type: theCatch._type,
      updatedAt: theCatch._updatedAt,
    }
  })
  return theCatchData
}

// #region QUERIES
const GET_CATCH_DATA_BY_HANDLES = (handles) => `
*[handle.current in [${handles.map((el) => `"${el}"`).join(',')}]]
{
  ...,
  "objectID": _id,
  content[]{
    ...,
    harvestMonth[]->{
      ...,
      fishArray[]{
        ...,
        locations->{
          ...,
          image{
            ...,
            asset->{...}
          }
        },
        species->{
          ...,
          image{
            ...,
            asset->{...}
          }
        },
        fishermen[]->{
          ...,
          image{
            ...,
            asset->{...}
          },
          flyoutImage{
            ...,
            asset->{...}
          },
          locations->{
            ...,
            image{
              ...,
              asset->{...}
            },
          }
        },
        culinary->{
          ...,
          image{
            ...,
            asset->{...}
          }
        }
      }
    },
    desktopBackgroundImage{
      ...,
      asset->{...}
    },
    mobileBackgroundImage{
      ...,
      asset->{...}
    },
    illustration{
      ...,
      asset->{...}
    },
    illustration2{
      ...,
      asset->{...}
    },
    mobileImage{
      ...,
      asset->{...}
    },
    desktopImage{
      ...,
      asset->{...}
    },
    image{
      ...,
      asset->{...}
    },
    recipeArticle->{
      ...,
      hero{
        ...,
        desktopBackgroundImage{
          ...,
          asset->{...}
        },
        mobileBackgroundImage{
          ...,
          asset->{...}
        }
      },
    },
  _type == "halfHeroHalfSlider" => {
    articles[]->{
        ...,
        seo{
          ...,
          shareGraphic{
            ...,
            asset->{...}
          }
        },
        hero{
          ...,
          desktopBackgroundImage{
            ...,
            asset->{...}
          },
          mobileBackgroundImage{
            ...,
            asset->{...}
          }
        },
        blog->{
          ...,
          seo{
            ...,
            shareGraphic{
              ...,
              asset->{...}
            }
          },
          content[]{
            ...,
            image{
              ...,
              asset->{...}
            },
            footerImage{
              ...,
              asset->{...}
            },
            desktopBackgroundImage{
              ...,
              asset->{...}
            },
            mobileBackgroundImage{
              ...,
              asset->{...}
            }
          },
          hero{
            ...,
            desktopBackgroundImage{
              ...,
              asset->{...}
            },
            mobileBackgroundImage{
              ...,
              asset->{...}
            }
          },
        },
        sidebar{
          ...,
          author->{
            ...,
            image{
              ...,
              asset->{...}
            }
          },
          knowYourFishList{
            ...,
            knowYourFishes[]->{
              ...,
              image{
                ...,
                asset->{...}
              },
              description->{
                ...,
              },
              content[]{
                ...,
                image{
                  ...,
                  asset->{...}
                },
                footerImage{
                  ...,
                  asset->{...}
                },
                illustration{
                  ...,
                  asset->{...}
                },
                illustration2{
                  ...,
                  asset->{...}
                },
                illustrationAlt{
                  ...,
                  asset->{...}
                },
                illustration2Alt{
                  ...,
                  asset->{...}
                },
              }
            }
          }
        }
      }
    }
  }
}
`
//#endregion
