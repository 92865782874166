import ImageReveal from '@/components/ImageReveal'
import classes from './ImageRevealSection.module.scss'
import List from '@/components/List'
import { useMemo } from 'react'
import imageUrlBuilder from '@sanity/image-url'
import { getSanityClient } from '@/services/sanityClient'
import Button from '@/components/Button/Button'
import Typography from '@/components/Typography/Typography'

const mapItemsPosition = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
}

const ImageRevealSection = ({ fields }) => {
  const {
    list,
    itemsPosition = 'center',
    spaceBetweenElements,
    images,
    buttons = [],
    buttonsArrangement,
    textList = [],
  } = fields

  const backgroundColor = useMemo(() => {
    if (fields.backgroundColor) {
      const { rgb } = fields.backgroundColor
      return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
    }
  }, [fields.backgroundColor])

  const mappedImages = useMemo(() => {
    if (images?.length) {
      return images?.map(({ asset, hotspot }) =>
        imageUrlBuilder(getSanityClient())
          .image(asset.url)
          .focalPoint(hotspot?.x || 0.5, hotspot?.y || 0.5)
          .crop('focalpoint')
          .fit('crop')
          .url(),
      )
    }
    return []
  }, [images])

  const [imageOne, imageTwo] = mappedImages

  return (
    <section className={classes['image-reveal-section']}>
      <div
        className={classes['image-reveal-section__content-container']}
        style={{
          alignItems: mapItemsPosition[itemsPosition],
          gap: spaceBetweenElements,
          background: backgroundColor,
        }}
      >
        {textList.map((props, i) => (
          <Typography
            key={`${i}-full-bleed-${props.text}`}
            as={i === 0 ? 'h2' : 'h3'}
            {...props}
          >
            {props.text}
          </Typography>
        ))}
        <List {...list} />
        {buttons?.length && (
          <div
            className={classes['full-bleed-video-container__buttons-container']}
            style={{
              flexDirection: buttonsArrangement ? buttonsArrangement : 'row',
            }}
          >
            {buttons.map((props, i) => (
              <Button
                key={`${i}-full-bleed-${props.text}`}
                as="link"
                {...props}
              >
                {props.text}
              </Button>
            ))}
          </div>
        )}
      </div>
      <div className={classes['image-reveal-section__review-container']}>
        <ImageReveal
          firstImage={imageOne}
          secondImage={imageTwo}
          hoverFeature
          showHandle
          centerResizer
        />
      </div>
    </section>
  )
}

export default ImageRevealSection
