import imageUrlBuilder from '@sanity/image-url'
import sanityClient from '@/services/sanityClient'
import { useMemo } from 'react'

const useImage = (image) => {
  const builder = imageUrlBuilder(sanityClient)

  const mainImage = useMemo(() => {
    if (!image || !image?.asset?.url) {
      return
    }

    if (!image?.asset?.metadata?.dimensions || !image?.hotspot) {
      return image.asset?.url
    }

    const imageHeight = image.asset.metadata.dimensions.height
    const imageWidth = image.asset.metadata.dimensions.width

    return builder
      .image(image)
      .rect(
        parseInt(image.crop.left * imageWidth),
        parseInt(image.crop.top * imageHeight),
        parseInt(image.hotspot.width * imageWidth),
        parseInt(image.hotspot.height * imageHeight),
      )
      .fit('max')
      .auto('format')
      .url()
  }, [image])

  return mainImage
}

export default useImage
