import GenericContainer from '@/components/GenericContainer'
import classes from './GenericHero.module.scss'
import Image from '@/components/Image'

const GenericHero = ({ fields: { content, backgroundImage } }) => {
  return (
    <section className={classes['generic-hero-module']}>
      {backgroundImage && (
        <div className={classes['generic-hero-module__background-container']}>
          {/* alt is one of the properties that are being spread below */}
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <Image {...backgroundImage} />
        </div>
      )}
      {content.map((containerProps) => (
        <GenericContainer
          mainContainer
          key={containerProps._key}
          {...containerProps}
        />
      ))}
    </section>
  )
}

export default GenericHero
