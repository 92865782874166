import classNames from 'classnames'
import classes from './Button.module.scss'
import colors from '@/styles/theme/colors'
import Link from 'next/link'
import DynamicIcon from '../DynamicIcon'

const Button = ({
  onClick,
  disabled,
  children,
  variant,
  fontFamily,
  color,
  textColor,
  rounded,
  fontSize,
  bold,
  as = 'link',
  fullWidth,
  href,
  iconPosition,
  iconName,
}) => {
  const Element = as === 'link' ? Link : 'button'
  return (
    <Element
      className={classNames(
        classes['button'],
        fontFamily === 'frank' && classes['button--frank'],
        rounded && classes['button--rounded'],
        fontSize && classes[`button--text-${fontSize}`],
        variant && classes[`button--${variant}`],
        bold && classes[`button--bold`],
        fullWidth && classes[`button--full-width`],
      )}
      style={{
        backgroundColor:
          variant !== 'light' && variant !== 'ghost' && colors[color],
        ...(variant !== 'light' && {
          borderColor: colors[color],
        }),
        color: colors[textColor],
      }}
      disabled={disabled}
      onClick={onClick}
      href={href}
    >
      {(!iconPosition || iconPosition === 'left') && iconName && (
        <DynamicIcon name={iconName} style={{ color: colors[textColor] }} />
      )}
      {children}
      {iconPosition === 'right' && iconName && (
        <DynamicIcon name={iconName} style={{ color: colors[textColor] }} />
      )}
    </Element>
  )
}

export default Button
