import { useEffect } from 'react'
import { useRouter } from 'next/router'
import classes from './CollectionList.module.scss'
import ProductCollectionCard from '@/components/Cards/ProductCollectionCard/ProductCollectionCard'
import { dataLayerViewProductList } from '@/utils/dataLayer'

const CollectionList = ({ fields }) => {
  const router = useRouter()
  const {
    backgroundColor: bgColor,
    description,
    header,
    mappedProducts,
    useFlyout,
    usePricing,
    useATC,
  } = fields

  useEffect(() => {
    // console.log('track collection list', mappedProducts)
    dataLayerViewProductList({ products: mappedProducts, url: router.asPath })
  }, [])

  return (
    <section
      style={{
        background: bgColor
          ? `rgba(${bgColor.rgb.r}, ${bgColor.rgb.g}, ${bgColor.rgb.b}, ${bgColor.rgb.a})`
          : null,
      }}
      className={`${classes['collection-list__container']}`}
    >
      {/* Collection Listing Title and Subtitle */}
      <div className={`${classes['collection-list__header']} container`}>
        <div role="contentinfo">
          <h1 style={{ color: header?.headerColor?.hex }}>
            {header?.headerText}
          </h1>
          <h3 style={{ color: description?.descriptionColor?.hex }}>
            {description?.descriptionText}
          </h3>
        </div>
      </div>

      {/* Collection Listing Title and Subtitle */}
      <ul className={`${classes['collection-list__product-list']} container`}>
        {mappedProducts.map(({ hoverFeature, ...product }) => (
          <ProductCollectionCard
            product={product}
            key={`${product.handle}-product-collection`}
            title={product.title}
            description={product.description}
            images={product.images}
            sanityImages={product?.sanityData?.productImages}
            sourceEntryId={product.sourceEntryId}
            hoverFeature={hoverFeature}
            usePricing={usePricing}
            useFlyout={useFlyout}
            useATC={useATC}
          />
        ))}
      </ul>
    </section>
  )
}

export default CollectionList
