const FullBleedQuestionnaire = ({ fields }) => {
  let questionnaireURL = `https://sitka-salmon-shares.jebbit.com/${fields.questionnaireID}?L=Full+Page&deferred=true`
  const style = {
    width: `100%`,
    height: `750px`,
    display: 'flex',
    justifyContent: 'center',
  }

  return (
    <>
      <iframe
        className="jebbit-iframe"
        src={questionnaireURL}
        seamless
        allowFullScreen
        style={style}
        onLoad={() => {
          function embedJebbit(t) {
            function e(t) {
              var e =
                'attach' === t
                  ? window.addEventListener
                  : window.removeEventListener
              e('DOMContentLoaded', n, !1),
                e('load', n, !1),
                e('scroll', n, !1),
                e('resize', n, !1)
            }
            var i = document.querySelector(t)
            function n() {
              var t, e, n, o
              ;(e = (t = i).getBoundingClientRect()),
                (n = t.clientHeight / 2),
                (o = t.clientWidth / 2),
                e.top >= 0 &&
                  e.left >= 0 &&
                  e.top <=
                    (window.innerHeight ||
                      document.documentElement.clientHeight) -
                      n &&
                  e.left <=
                    (window.innerWidth ||
                      document.documentElement.clientWidth) -
                      o &&
                  i.contentWindow.postMessage('startJebbitCampaign', '*')
            }
            window.addEventListener(
              'message',
              function t(i) {
                i &&
                  'https://sitka-salmon-shares.jebbit.com' === i.origin &&
                  ('jebbitSessionCreated' === i.data
                    ? e('remove', n)
                    : 'jebbitCampaignLoaded' === i.data
                      ? n()
                      : i.data &&
                        'redirect' === i.data.action &&
                        i.data.options &&
                        i.data.options.link &&
                        (window.location.href = i.data.options.link))
              },
              !1,
            ),
              e('attach')
          }
          embedJebbit('.jebbit-iframe')
        }}
      />
    </>
  )
}

export default FullBleedQuestionnaire
