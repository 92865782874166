import classes from './ArticleProduct.module.scss'
import { usePDPDrawerContext } from '@/context/PDPDrawerContext'
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'
import ResponsiveImage from '@/components/ResponsiveImage'
import Image from 'next/image'
import Link from 'next/link'
import ResponsiveComponent from '@/components/ResponsiveComponent'
import { getVariantSubscribedPrice } from '@/utils/formatPrice'
import { unitMapping } from '@/utils/weightUnits'

const articleCardImgLoader = ({ src }) => {
  return `${src}?w=400`
}

const ArticleProduct = ({ product, parentClasses }) => {
  const PDPDrawerContext = usePDPDrawerContext()
  const { addItemToOrder } = useHeadlessCheckoutContext()

  const isSubscription =
    product.tags.includes('Subscription Box') &&
    product.content.handle !== 'sitka-seafood-intro-box'
  const isIntroBox = product.content.handle === 'sitka-seafood-intro-box'

  if (!product || !product.content) {
    return ''
  }

  return (
    <div
      className={`${classes['article-product']} ${parentClasses['article-section']}`}
    >
      <div className={classes['article-product__card']}>
        <div className={classes['article-product__content']}>
          <div className={classes['article-product__image']}>
            <ResponsiveComponent
              desktopBreakpoint={'md'}
              desktop={
                <ResponsiveImage
                  loader={articleCardImgLoader}
                  src={product.content.media[0].src}
                  alt={
                    product.content.media[0].altText || product.content.title
                  }
                />
              }
              mobile={
                <Image
                  layout="fill"
                  src={product.content.media[0].src}
                  alt={
                    product.content.media[0].altText || product.content.title
                  }
                />
              }
            />
          </div>
          <div className={classes['article-product__details']}>
            <h4 className="heading--product-title">{product.content.title}</h4>
            <div
              className={`${classes['article-product____tier-price-pounds']} secondary--body`}
            >
              <span>
                $
                {isSubscription
                  ? getVariantSubscribedPrice({ variant: product.variants[0] })
                  : product.variants[0].price}{' '}
                {isSubscription && <>/ box </>}
              </span>
              {product?.variants?.[0]?.weight && (
                <span>
                  {product.variants[0].weight}{' '}
                  {unitMapping[product.variants[0].weightUnit] ??
                    product.variants[0].weightUnit?.toLowerCase() ??
                    'units'}
                </span>
              )}
            </div>
            <div className={classes['article-product__details-footer']}>
              {isSubscription || isIntroBox ? (
                <button
                  onClick={() =>
                    PDPDrawerContext.openDrawer({ productData: product })
                  }
                  className="btn-link-underline"
                >
                  Details & Projected Harvest
                </button>
              ) : (
                <button
                  onClick={() =>
                    PDPDrawerContext.openProductDetailDrawer({
                      productData: product,
                    })
                  }
                  className="btn-link-underline"
                >
                  View Details
                </button>
              )}
              {isSubscription ? (
                <Link href="/pages/choose-your-plan" legacyBehavior>
                  <button className="btn salmon">Subscribe Now</button>
                </Link>
              ) : (
                <button
                  onClick={async () => {
                    await addItemToOrder({
                      variant: product.variants[0],
                      product,
                      openFlyout: true,
                    })
                  }}
                  className="btn salmon"
                >
                  Add to Cart
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleProduct
