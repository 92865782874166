import classNames from 'classnames'
import classes from './List.module.scss'
import IconLongArrow from '@/svgs/long-arrow-right.svg'
import Typography from '../Typography/Typography'
import colors from '@/styles/theme/colors'

const iconMapping = {
  small: { width: 20, height: 10 },
  medium: { width: 30, height: 12 },
  large: { width: 34, height: 14 },
}
const List = ({
  items,
  showIcon,
  iconColor = 'sitkaBlue',
  typography,
  iconSize = 'medium',
}) => {
  return (
    <ul
      className={classes['list']}
      style={{ color: colors[iconColor], listStyle: showIcon && 'none' }}
    >
      {items.map((item) => (
        <li
          key={`list-item-${item}`}
          className={classNames(showIcon && classes['list__item-with-icon'])}
        >
          {showIcon && (
            <span className={classes['list__icon-container']}>
              <IconLongArrow
                style={{
                  color: colors[iconColor],
                  ...iconMapping[iconSize],
                }}
              />
            </span>
          )}
          <Typography {...typography} as="span">
            {item}
          </Typography>
        </li>
      ))}
    </ul>
  )
}

export default List
