import { useEffect, useState } from 'react';
import classes from './WYSIWYG.module.scss'
import { PortableText } from '@portabletext/react'
import Link from 'next/link'
import ArticleProduct from '@/components/Article/ArticleProduct'
import Video from '@/components/Video'
import ResponsiveImage from '@/components/ResponsiveImage'
import { getProductByHandle } from '@/utils/getProducts';

const valuePropImageLoader = ({ src }) => {
  return `${src}?w=240`
}

const WYSIWYG = ({ fields }) => {
  const [products, setProducts] = useState(null)
  useEffect(() => {
    const productHandles = fields.content.reduce((handles, c) => {
      if (c.product) handles.push(c.product)
      return handles
    }, [])
    if (productHandles > 0) {
      Promise.all(
        productHandles.map(async handle => {
          return await getProductByHandle(handle)
        })
      )
        .then(data => setProducts(data))
    }
  }, [])

  const myPortableTextComponents = {
    marks: {
      link: ({ children, value }) => {
        if (!value.href) return <span>{children}</span>
        if (value.href.includes('mailto')) {
          return <a rel="noreferrer noopener" href={value.href} target="_blank">{children}</a>
        }
        return (
          <Link href={value.href}>
            {children}
          </Link>
        );
      },
      color: ({ children, value }) => {
        return <span style={{ 'color': value.hex }}>{children}</span>
      },
    },
    listItem: {
      bullet: ({ children }) => {
        return (
          <li className="body">{children}</li>
        )
      },
      number: ({ children }) => {
        return (
          <li className="body">{children}</li>
        )
      }
    },
    types: {
      image: ({ value }) => {
        if (value.link) {
          return (
            <div className={classes['wysiwyg__image']}>
              <Link href={value.link}>

                <ResponsiveImage src={value.asset.url} alt={value.asset.alt || ''} />

              </Link>
              {value.caption && <span className={classes['wysiwyg__image-caption']}>{value.caption}</span>}
            </div>
          );
        }
        return (
          <div className={classes['wysiwyg__image']}>
            {value?.asset &&
              <ResponsiveImage sizes="(min-width: 1080px) 65vw, (min-width: 1920px) 1200px, 100vw" src={value.asset.url} alt={value.asset.alt || ''} />
            }
            {value.caption && <span className={classes['wysiwyg__image-caption']}>{value.caption}</span>}
          </div>
        )
      },
      productBlock: ({ value }) => {
        if (!products || products.length < 1) return <></>
        const product = products.find(product => product.content.handle === value.product)
        if (product) return <ArticleProduct product={product} parentClasses={classes} />
        return <></>
      },
      youtubeVideoBlock: ({ value }) => <Video youtubeVideoId={value.youtubeVideoId} autoplay={false} startVideo="true" youtubeOptions={{ 'height': '521' }} className={classes['wysiwyg__video']} />,
      valuePropsBlock: ({ value }) => {
        let valuePropsKeys = Object.keys(value).filter(key => key.includes('valueProp'))
        if (valuePropsKeys.length < 1) return ''
        valuePropsKeys = valuePropsKeys.sort()
        return <ul className={classes['wysiwyg__value-props']}>
          {valuePropsKeys.map(key => {
            const valueProp = value[key]
            if (!valueProp) {
              return ''
            }
            const image = valueProp.image
            return (<li key={key} className={classes['wysiwyg__value-prop']}>
              <div className={classes['wysiwyg__value-prop-container']}>
                {image.asset && <div className={classes['wysiwyg__value-prop-image']}>
                  <ResponsiveImage loader={valuePropImageLoader} src={image.asset.url} alt={valueProp.text} />
                </div>}
                <h3>{valueProp.text}</h3>
              </div>
            </li>)
          })}
        </ul>
      }
    }
  }

  return (
    <div className={classes['wysiwyg']}>
      <PortableText components={myPortableTextComponents} value={fields.content} />
      {fields.ctaText && fields.ctaUrl && (
        <a className={`btn ${fields.ctaColor} ${classes['wysiwyg__btn']} ${fields.ctaCentered ? classes['centered'] : ''}`} href={fields.ctaUrl}>{fields.ctaText}</a>
      )}
    </div>
  )
}

export default WYSIWYG
